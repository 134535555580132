import React, { useEffect } from "react";
import * as styles from "./postCaseStudy.module.scss";

import Layout from "../../Layout/MainPageLayout";
// import Breadcrumbs from "../Navigation/Breadcrumbs/Breadcrumbs";
import PageHeader from "../PageHeaders/PageHeader";
import ImageCarouselController from "../../ImageCarousel/ImageCarouselController";
import { workRoute } from "../../../NavItems";

export default function CaseStudyTemplate(props) {
  const { pageContext } = props;
  const caseStudy = pageContext;

  const carouselImages1 = caseStudy.carousel1;
  const carouselImages2 = caseStudy.carousel2;

  useEffect(() => {
    const links = document.querySelectorAll("article a");
    links.forEach((link) => link.setAttribute("target", "_blank"));
  });
  return (
    <Layout
      pageTitle={caseStudy.title}
      pageDescription={caseStudy.description}
      selected="Case Studies"
    >
      <div className={styles.pageContainer}>
        <div className={styles.wrapper}>
          <PageHeader
            heading={caseStudy.title}
            topics={caseStudy.topics}
            toLink={`${workRoute}`}
            linkName="Case Studies"
            projectType="Case Study"
            startDate={caseStudy.startDate}
            endDate={caseStudy.endDate ? caseStudy.endDate : "Now"}
          />

          <article>
            <div
              dangerouslySetInnerHTML={{
                __html: caseStudy.body,
              }}
            ></div>

            {carouselImages1 ? (
              <ImageCarouselController imageSet={carouselImages1} />
            ) : null}
            <div>
              <div
                dangerouslySetInnerHTML={{
                  __html: caseStudy.body2 || null,
                }}
              ></div>
            </div>
            {carouselImages2 ? (
              <ImageCarouselController imageSet={carouselImages2} />
            ) : null}
            <div>
              <div
                dangerouslySetInnerHTML={{
                  __html: caseStudy.body3 || null,
                }}
              ></div>
              <div className={styles.videosContainer}>
                {caseStudy.prototypes
                  ? caseStudy.prototypes.map((video, i) => {
                      return (
                        <video
                          className={[
                            styles.prototypeVideo,
                            styles[`prototypeVideo${i + 1}`],
                          ].join(" ")}
                          controls
                        >
                          <source src={video.file.url} type="video/mp4" />
                          Your browser does not support HTML video.
                        </video>
                      );
                    })
                  : null}
              </div>
            </div>
          </article>
        </div>
      </div>
    </Layout>
  );
}
